import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from '@material-ui/core';
import { currentNetwork } from './networkConfig';

export const displayToastHash = (hash) => {
  const lien = `${currentNetwork.explorer}/tx/${hash}`;
  toast(
    <Link href={lien} target="_blank">
      {' '}
      {hash}{' '}
    </Link>
  );
};

export const displayToast = (message) => {
  toast(message);
};

export const displayErrorToast = (message) => {
  toast(message, { type: 'error' });
};

export const truncate = (fullStr, strLen, separator) => {
  if (fullStr.length <= strLen) return fullStr;

  separator = separator || '....';

  const sepLen = separator.length;
  const charsToShow = strLen - sepLen;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars);
};

export const tokenHref = (address) => {
  const lien = `${currentNetwork.explorer}/token/${address}`;
  return (
    <Link href={lien} target="_blank">
      {' '}
      {truncate(address, 15, '...')}{' '}
    </Link>
  );
};
