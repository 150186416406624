import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { LoadingButton } from '@material-ui/lab';
import { UserContext } from '../../Context/UserContext';
import { walletConnect, walletSetData } from '../../../cryptos/contractsManager/walletConnector';
import { currentNetwork } from '../../../utils/networkConfig';
// ----------------------------------------------------------------------

export default function LoginForm(props) {
  const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const userCopy = { ...user };
      userCopy.loading = true;
      setUser(userCopy);
      const data = await walletConnect();
      userCopy.loading = true;
      setUser(userCopy);
      if (!data.connected) return;
      userCopy.account = data.account;
      userCopy.networkId = data.networkId;
      userCopy.web3 = data.web3;
      userCopy.connected = data.connected;
      userCopy.loading = false;
      localStorage.setItem(
        'currentUser',
        JSON.stringify({
          account: userCopy.account,
          networkId: userCopy.networkId,
          connected: userCopy.connected
        })
      );
      setUser(userCopy);
      const dataCollect = await walletSetData(userCopy);
      // eslint-disable-next-line array-callback-return
      Object.keys(dataCollect).map((key) => {
        userCopy.key = dataCollect.key;
      });
      setUser(userCopy);
      navigate('/dashboard', { replace: true });
    } catch (error) {
      console.log(error);
      const userCopy = { ...user };
      userCopy.loading = false;
      setUser(userCopy);
    }
  };
  return (
    <LoadingButton
      fullWidth
      size="large"
      type="button"
      variant="contained"
      loading={user.loading}
      onClick={handleLogin}
    >
      Connecter au réseau ({currentNetwork.name})
    </LoadingButton>
  );
}
