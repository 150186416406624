import React, { useContext, useState } from 'react';
// material
import { Container, Typography } from '@material-ui/core';
// components
import { Alert, AlertTitle } from '@material-ui/lab';
import Page from '../components/Page';
//
import { UserContext } from '../components/Context/UserContext';
import BallotList from '../cryptos/display/BallotList';

// ----------------------------------------------------------------------

export default function Ballots() {
  const [user] = useContext(UserContext);
  return (
    <Page title="Dashboard: Votes | Capital Manager">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Votes
        </Typography>
        {user.ballotDataList.length > 0 && (
          <BallotList ballotDataList={user.ballotDataList} user={user} />
        )}
        {user.ballotDataList.length === 0 && (
          <Alert variant="filled" severity="info">
            <AlertTitle>OUPS</AlertTitle>
            Pas de Vote pour le moment. Revenez verifier de temps de en temps.
            <br /> Merci!
          </Alert>
        )}
      </Container>
    </Page>
  );
}
