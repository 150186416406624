import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import { useContext } from 'react';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import DashboardApp from './pages/DashboardApp';
import NotFound from './pages/Page404';
import { UserContext } from './components/Context/UserContext';
import Batches from './pages/Batches';
import { walletSetData } from './cryptos/contractsManager/walletConnector';
import Admin from './pages/Admin';
import Idos from './pages/Idos';
import Logout from './pages/Logout';
import Ballots from './pages/Ballots';

// ----------------------------------------------------------------------

export default function Router() {
  const [user, setUser] = useContext(UserContext);
  const loadData = async () => {
    const userCopy = { ...user };
    const dataCollect = await walletSetData(userCopy);
    // eslint-disable-next-line array-callback-return
    Object.keys(dataCollect).map((key) => {
      userCopy[key] = dataCollect[key];
    });
    setUser(userCopy);
  };

  if (user.connected) {
    loadData();
    /* const interval = setInterval(async () => {
      loadData();
    }, 9000000); */
  }

  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: '/',
          element: !user.connected ? (
            <Navigate to="/login" replace />
          ) : (
            <Navigate to="/dashboard/app" replace />
          )
        },
        {
          path: 'app',
          element: !user.connected ? <Navigate to="/login" replace /> : <DashboardApp user={user} />
        },
        {
          path: 'batches',
          element: !user.connected ? <Navigate to="/login" replace /> : <Batches />
        },
        {
          path: 'votes',
          element: !user.connected ? <Navigate to="/login" replace /> : <Ballots />
        },
        {
          path: 'ido',
          element: !user.connected ? <Navigate to="/login" replace /> : <Idos />
        },
        {
          path: 'admin',
          element: !user.isAdmin ? <Navigate to="/dashboard" replace /> : <Admin />
        }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: 'login',
          element: !user.connected ? <Login /> : <Navigate to="/dashboard/app" replace />
        },
        {
          path: 'logout',
          element: !user.connected ? <Login /> : <Logout />
        },
        { path: '404', element: <NotFound /> },
        {
          path: '/',
          element: !user.connected ? <Login /> : <Navigate to="/dashboard/app" replace />
        },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
