import PropTypes from 'prop-types';
// material
import { Divider, Grid, Stack } from '@material-ui/core';
import { useContext } from 'react';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { UserContext } from '../../components/Context/UserContext';
import BatchCard from './BatchCard';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(3),
  height: theme.spacing(2),
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
  color: theme.palette.background.default,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.error.dark,
    0.24
  )} 100%)`
}));
BatchList.propTypes = {
  batchDataList: PropTypes.array.isRequired
};

export default function BatchList({ batchDataList, ...other }) {
  const [user, setUser] = useContext(UserContext);
  return (
    <>
      <Grid container spacing={3} {...other}>
        {batchDataList.map((batch) => (
          <Grid key={batch.id} item xs={12} sm={6} md={4}>
            <BatchCard batch={batch} user={user} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
