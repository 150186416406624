const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const timeConverter = (timestamp) => {
  // eslint-disable-next-line camelcase
  const a = new Date(timestamp * 1000);
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
  const hour = a.getHours();
  const min = a.getMinutes();
  const sec = a.getSeconds();
  return `${date} ${month} ${year} ${hour}:${min}:${sec}`;
};

export const getYearPlusOne = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear() + 1;
  const month = months[date.getMonth()];
  const day = date.getDate();
  const hour = date.getHours();
  const min = date.getMinutes();
  const sec = date.getSeconds();
  return `${day} ${month} ${year} ${hour}:${min}:${sec}`;
};
