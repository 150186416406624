import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import bankFilled from '@iconify/icons-ant-design/bank-filled';
import emailFilled from '@iconify/icons-eva/email-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Gestion du Capital',
    path: '/dashboard/batches',
    icon: getIcon(bankFilled)
  },
  {
    title: 'Liste des Idos',
    path: '/dashboard/ido',
    icon: getIcon(bankFilled)
  },
  {
    title: 'Votes',
    path: '/dashboard/votes',
    icon: getIcon(emailFilled)
  },
  {
    title: 'Admin',
    path: '/dashboard/admin',
    icon: getIcon(shoppingBagFill)
  } /* ,
  {
    title: 'blog',
    path: '/dashboard/blog',
    icon: getIcon(fileTextFill)
  },
  {
    title: 'login',
    path: '/login',
    icon: getIcon(lockFill)
  },
  {
    title: 'register',
    path: '/register',
    icon: getIcon(personAddFill)
  },
  {
    title: 'Not found',
    path: '/404',
    icon: getIcon(alertTriangleFill)
  } */
];

export default sidebarConfig;
