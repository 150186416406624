// material
import { Box, Typography, Stack, Divider, Link, TextareaAutosize } from '@material-ui/core';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
// utils
//
import React from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';

import { makeStyles } from '@material-ui/styles';
import { Alert, AlertTitle, LoadingButton } from '@material-ui/lab';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import { displayErrorToast, tokenHref, truncate } from '../../utils/utilsCeca';
import IndoParticipationForm from './IdoForm';
import {
  idoRedistribute,
  walletLockIdo,
  walletSetIdoTokenInformation
} from '../contractsManager/walletIdo';

// ----------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  imageIdo: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    width: '3%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'block'
  }
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(3),
  height: theme.spacing(2),
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
  color: theme.palette.background.default,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.error.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function IdoCard({ ido, user, className }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [idoAction, setIdoAction] = React.useState(false);

  if (className === 'opened' && !ido.aIdo.isLocked && !ido.aIdo.isCompleted) {
    className = classes.visible;
  } else if (className === 'completed' && ido.aIdo.isCompleted) {
    className = classes.visible;
  } else {
    className = classes.hidden;
  }

  const boxClass =
    // eslint-disable-next-line no-nested-ternary
    !ido.aIdo.isLocked && !ido.aIdo.isEligible
      ? 'warning.main'
      : ido.aIdo.isCompleted
      ? 'error.main'
      : 'background.paper';

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const lockIdo = async () => {
    try {
      await walletLockIdo(user, ido.aIdo.idoId);
    } catch (e) {
      displayErrorToast('Une erreure est survenue');
    }
    return false;
  };
  const remaining = ido.aIdo.numberOfTokenFromIdo * 0.99 - ido.aIdo.numberOfTokenDistributed;

  const handleIdoChange = (event) => {
    const data = event.target.value;
    setIdoAction(data);
  };

  const handleIdoActionSubmit = async (event) => {
    event.preventDefault();
    try {
      // eslint-disable-next-line no-unused-expressions
      const dataList = idoAction.split('\n');
      if (dataList.length > 1) {
        setIdoAction('');
        displayErrorToast('Erreur sur les donnees');
      }
      const infos = dataList[0].trim().split(',');
      if (infos.length < 3) {
        setIdoAction('');
        displayErrorToast('Erreur sur les donnees');
      }
      await walletSetIdoTokenInformation(user, infos[0], infos[1], infos[2], ido.aIdo.idoId);
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  const handleIdoRedistribute = async (event) => {
    event.preventDefault();
    try {
      await idoRedistribute(user, ido.aIdo.idoIndex);
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  return (
    <Box boxShadow={3} bgcolor={boxClass} m={1} p={1} className={className}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={7}>
            <img src={ido.aIdo.image} alt="" className={classes.imageIdo} />
            <Typography variant="subtitle2">
              {ido.aIdo.name} # {tokenHref(ido.aIdo.idoId)}
            </Typography>
            <Typography variant="subtitle2">
              Mon dépôt:${ido.aIdo.balance} / Prix du token:${ido.aIdo.priceSpentPerToken}
            </Typography>
            <Typography variant="subtitle2">
              Participants:{ido.aIdo.numberOfParticipants} / Total dépôt:${ido.aIdo.totalDeposited}
            </Typography>
            <Typography variant="subtitle2">Mon poids : {ido.aIdo.weight}</Typography>
            <Typography variant="subtitle2">Ouvert le : {ido.aIdo.openedTimestamp}</Typography>
            <Typography variant="subtitle2">Fermé le : {ido.aIdo.lockTimestamp}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={5}>
            <Typography variant="subtitle2">Ouvert le : {ido.aIdo.openedTimestamp}</Typography>
            <Typography variant="subtitle2">
              Addresse du Token :{tokenHref(ido.aIdo.tokenAddress)}
            </Typography>
            <Typography variant="subtitle2">
              Partication totale :{ido.aIdo.totalDeposited}
            </Typography>
            <Typography variant="subtitle2">Manque a redistribuer :{remaining}</Typography>
          </Stack>
          <Divider />
          {ido.isLocked && (
            <Typography variant="subtitle2">Ido Clos(Impossible de deposer)</Typography>
          )}
          {!ido.aIdo.isLocked && !ido.aIdo.isEligible && (
            <Alert variant="filled" severity="error">
              <AlertTitle>Information</AlertTitle>
              Soit le montant déposé en capital n'est pas suffisant ({user.me.myTotalDeposited}$ /
              Min = 100$), <br /> Soit vous n'avez pas tout vos Ceca dans votre portefeuille <br />
              Soit Aucun batch Clos <br /> Soit vous avez deja deposé le maximum par utilisateur
            </Alert>
          )}
          {!ido.aIdo.isLocked && ido.aIdo.isEligible && (
            <IndoParticipationForm ido={ido.aIdo} user={user} />
          )}
          {user.isAdmin && (
            <Alert variant="filled" severity="warning">
              <AlertTitle>Administration</AlertTitle>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={5}>
                {!ido.aIdo.isLocked && (
                  <Link href="#" onClick={lockIdo}>
                    Bloquer
                  </Link>
                )}
                <Typography variant="subtitle2">
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="_tokenAddress,_numberOfToken, _totalBusdSpent"
                    label=""
                    value={idoAction}
                    onChange={handleIdoChange}
                  />
                </Typography>
                <Typography variant="subtitle2">
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleIdoActionSubmit}
                  >
                    Mettre a jours
                    <IconWrapperStyle>
                      <Icon icon={editFill} width={44} height={44} />
                    </IconWrapperStyle>
                  </LoadingButton>
                </Typography>
                <Typography variant="subtitle2">
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleIdoRedistribute}
                  >
                    Redistribuer
                    <IconWrapperStyle>
                      <Icon icon={editFill} width={44} height={44} />
                    </IconWrapperStyle>
                  </LoadingButton>
                </Typography>
              </Stack>
            </Alert>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
