import React from 'react';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Stack } from '@material-ui/core';
import { idoParticipate } from '../contractsManager/walletIdo';

class IndoParticipationForm extends React.Component {
  constructor(props) {
    super(props);
    const { ido, user } = this.props;
    this.state = {
      loading: false,
      ido,
      user,
      amount: ''
    };
  }

  handleChange = (event) => {
    const amount = event.target.value;
    this.setState({ amount });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      this.state.loading = true;
      await idoParticipate(this.state.user, this.state.ido.idoId, this.state.amount);
      this.state.loading = false;
      this.setState({ amount: '' });
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  render() {
    const { amount } = this.state;
    const { maxCanDeposit } = this.state.ido;
    return (
      <ValidatorForm onSubmit={this.handleSubmit} onError={(errors) => console.log(errors)}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          Max : {maxCanDeposit}
          <TextValidator
            label="Montant"
            onChange={this.handleChange}
            name="Montant"
            value={amount}
            validators={['required', 'minNumber:1', 'matchRegexp:^[0-9]$']}
            errorMessages={[
              'this field is required',
              `Montant pas Valide (entre 1 et ${{ maxCanDeposit }} BUSD)`
            ]}
          />
          <Button type="submit">Submit</Button>
        </Stack>
      </ValidatorForm>
    );
  }
}
export default IndoParticipationForm;
