import React from 'react';
import CeCaBatch from '../abis/CeCaBatch.json';
import BatchCreator from '../abis/BatchCreator.json';
import { displayErrorToast, displayToastHash } from '../../utils/utilsCeca';
import { fromWei, toWei } from './walletUtils';
import { mockImgBatch } from '../../utils/mockImages';
import { getYearPlusOne, timeConverter } from './dateManager';
import { gasParams } from '../../utils/networkConfig';

export const getBatchsData = async (statesData) => {
  let totalBatchNumber = await statesData.capitalManager.methods.getBatchListSize().call();
  totalBatchNumber = totalBatchNumber.toString();

  const batchDataList = [];
  let myTotalDeposited = 0;
  for (let i = 0; i < totalBatchNumber; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const aBatchAddress = await statesData.capitalManager.methods.batchList(i).call();
    const ceCaBatch = getCeCaBatchContract(statesData, aBatchAddress);
    const aBatch = { batchId: aBatchAddress };
    // eslint-disable-next-line no-await-in-loop
    aBatch.totalDeposited = await ceCaBatch.methods.totalDeposited().call();

    aBatch.totalDeposited = fromWei(statesData, aBatch.totalDeposited);
    aBatch.cover = mockImgBatch(aBatch.batchId);
    // eslint-disable-next-line no-await-in-loop
    const totalParticipants = await ceCaBatch.methods.getNumberOfParticipantOfBatch().call();
    aBatch.totalParticipants = fromWei(statesData, totalParticipants.toString());

    // eslint-disable-next-line no-await-in-loop
    const myBatchCap = await ceCaBatch.methods.getListOfParticipant(statesData.account).call();
    aBatch.myBatchCap = fromWei(statesData, myBatchCap.toString());
    myTotalDeposited += parseFloat(aBatch.myBatchCap);
    // eslint-disable-next-line no-await-in-loop
    const participants = await ceCaBatch.methods.getNumberOfParticipantOfBatch().call();
    aBatch.batchParticipants = participants.toString();
    // eslint-disable-next-line no-await-in-loop
    aBatch.name = await ceCaBatch.methods.name().call();
    const nameD = aBatch.name.toString().split('|');
    aBatch.name = nameD[0].toString();
    aBatch.description = nameD[1] ? nameD[1].toString() : '';

    // eslint-disable-next-line no-await-in-loop
    aBatch.lockTimestamp = await ceCaBatch.methods.lockTimestamp().call();
    // eslint-disable-next-line no-await-in-loop
    aBatch.isLocked = await ceCaBatch.methods.isLocked().call();
    // eslint-disable-next-line no-await-in-loop
    aBatch.openedTimestamp = await ceCaBatch.methods.openedTimestamp().call();

    const aLockTimestamp = aBatch.lockTimestamp;
    if (aBatch.lockTimestamp !== '' && aBatch.isLocked) {
      aBatch.lockTimestamp = timeConverter(aBatch.lockTimestamp);
    } else {
      aBatch.lockTimestamp = 'Encore Ouvert';
    }

    aBatch.openedTimestamp = timeConverter(aBatch.openedTimestamp);
    if (aBatch.isLocked) aBatch.ClaimDateBegin = getYearPlusOne(aLockTimestamp);
    else aBatch.ClaimDateBegin = 'Encore Ouvert';

    // My Balance
    aBatch.myBatchPercentage = 0;
    if (aBatch.totalDeposited > 0) {
      aBatch.myBatchPercentage = (aBatch.myBatchCap / aBatch.totalDeposited) * 100;
      aBatch.myBatchPercentage = aBatch.myBatchPercentage.toFixed(5);
    }
    batchDataList.push(aBatch);
  }
  return { batchDataList, myTotalDeposited, totalBatchNumber };
};

export const disconnect = () => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
  sessionStorage.clear();
  localStorage.removeItem('currentUser');
};

export const walletStakeTokens = async (statesData, batchId, amount) => {
  console.log(statesData.busdTokenBalance, amount);
  if (parseFloat(amount) > parseFloat(statesData.busdTokenBalance)) {
    displayErrorToast('Solde Insuffisant');
    return false;
  }
  try {
    amount = toWei(statesData, amount);
    const ceCaBatch = getCeCaBatchContract(statesData, batchId);
    await approve(statesData, statesData.busdToken, batchId, statesData.account, amount);

    let myBatchCap = await ceCaBatch.methods.getListOfParticipant(statesData.account).call();
    myBatchCap = parseInt(fromWei(statesData, myBatchCap.toString()), 10);

    let gasToUse = '155000';
    if (myBatchCap === 0) {
      gasToUse = '360000';
    }
    const data = await ceCaBatch.methods
      .depositInCapital(amount)
      .send({ from: statesData.account, gasPrice: gasParams.gasPrice, gas: gasToUse })
      .on('transactionHash', (hash) => {
        displayToastHash(hash);
      });
  } catch (e) {
    displayErrorToast("Une erreur s'est produite");
  }
};

export const walletUnstakeTokens = (statesData) => {
  // TODO
  try {
    statesData.tokenFarm.methods
      .unstakeTokens()
      .send({ from: statesData.account })
      .on('transactionHash', (hash) => {
        displayToastHash(hash);
      });
  } catch (e) {
    displayErrorToast("Une erreur s'est produite");
  }
};

// should be call by main wallet
export const walletRedistributeToken = async (walletData, receivers, amounts) => {
  const statesData = walletData;
  if (receivers.length < 1 || receivers.length !== amounts.length) return false;
  for (let i = 0; i < receivers.length; i += 1) {
    amounts[i] = toWei(statesData, amounts[i].toString());
  }
  try {
    await statesData.capitalManager.methods
      .redistributeToOldInvestor(receivers, amounts)
      .send({ from: statesData.account })
      .on('transactionHash', (hash) => {
        displayToastHash(hash);
      });
  } catch (e) {
    displayErrorToast("Une erreur s'est produite");
  }
};

export const createNewBatch = async (statesData, description) => {
  const batchCreator = BatchCreator.networks[statesData.networkId];
  const batchCreatorContact = new statesData.web3.eth.Contract(
    BatchCreator.abi,
    batchCreator.address,
    gasParams
  );
  const bNB = statesData.totalBatchNumber;
  try {
    batchCreatorContact.methods
      .createAppendBatch(`Batch ${bNB} | ${description}`, false)
      .send({ from: statesData.account, gasPrice: gasParams.gasPrice, gas: '3806802' })
      .on('transactionHash', (hash) => {
        displayToastHash(hash);
      });
  } catch (e) {
    console.log(e);
    displayErrorToast("Une erreur s'est produite");
  }
};

export const walletLockBatch = async (statesData, batchId) => {
  const ceCaBatch = getCeCaBatchContract(statesData, batchId);
  try {
    await ceCaBatch.methods
      .lockBatch()
      .send({ from: statesData.account, gasPrice: gasParams.gasPrice, gas: '90000' })
      .on('transactionHash', (hash) => {
        displayToastHash(hash);
      });
  } catch (e) {
    displayErrorToast("Une erreur s'est produite");
  }
};

async function approve(statesData, contract, address, from, amount) {
  let allowanceBefore = await contract.methods.allowance(from, address).call();
  allowanceBefore = fromWei(statesData, allowanceBefore.toString());
  const amountFroWei = fromWei(statesData, amount);
  if (parseFloat(allowanceBefore) < parseFloat(amountFroWei)) {
    await contract.methods
      .approve(address, '100000000000000000000000000000000')
      .send({ from })
      .on('transactionHash', (hash) => {
        displayToastHash(hash);
      });
  }
}

const getCeCaBatchContract = (statesData, batchId) =>
  new statesData.web3.eth.Contract(CeCaBatch.abi, batchId, gasParams);
