import faker from 'faker';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import textSms from '@iconify/icons-ic/textsms';
import book from '@iconify/icons-ic/book';
// material
import {
  Box,
  Grid,
  Card,
  Paper,
  Typography,
  CardHeader,
  CardContent,
  Link
} from '@material-ui/core';
// utils
import youtubeFill from '@iconify/icons-ant-design/youtube-fill';
import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const SOCIALS = [
  {
    name: 'FaceBook',
    value: 1500,
    icon: <Icon icon={facebookFill} color="#1877F2" width={32} height={32} />,
    link: 'https://www.facebook.com/konjohelvis'
  },
  {
    name: 'Telegram',
    value: 4000,
    icon: <Icon icon={textSms} color="#DF3E30" width={32} height={32} />,
    link: 'https://t.me/cryptoedu_p'
  },
  {
    name: 'Youtube',
    value: 15000,
    icon: <Icon icon={youtubeFill} color="#FF0000" width={32} height={32} />,
    link: 'https://www.youtube.com/c/elviskonjoh'
  },
  {
    name: 'Twitter',
    value: 100,
    icon: <Icon icon={twitterFill} color="#1C9CEA" width={32} height={32} />,
    link: 'https://twitter.com/selabielvis'
  },
  {
    name: 'CryptoEDU',
    value: 10000,
    icon: <Icon icon={book} color="#1C9CEA" width={32} height={32} />,
    link: 'https://cryptoedu.xyz'
  },
  {
    name: 'Livre Blanc',
    value: 10000,
    icon: <Icon icon={book} color="#1C9CEA" width={32} height={32} />,
    link: 'http://a.mhi2s.xyz/G1FKL'
  }
];

// ----------------------------------------------------------------------

SiteItem.propTypes = {
  site: PropTypes.object
};

function SiteItem({ site }) {
  const { icon, value, name, link } = site;

  return (
    <Grid item xs={3}>
      <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center' }}>
        <Box sx={{ mb: 0.5 }}>{icon}</Box>
        <Typography variant="h6">{fShortenNumber(value)}</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <Link href={link} color="inherit" underline="hover" target="_blank">
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </Link>
        </Typography>
      </Paper>
    </Grid>
  );
}

export default function AppTrafficBySite() {
  return (
    <Card>
      <CardHeader title="Liens Utiles" />
      <CardContent>
        <Grid container spacing={2}>
          {SOCIALS.map((site) => (
            <SiteItem key={site.name} site={site} />
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
