import React from 'react';
import Web3 from 'web3';
import CryptoEduCapitalToken from '../abis/CryptoEduCapitalToken.json';
import FBusd from '../abis/FBusd.json';
import CapitalManager from '../abis/CapitalManager.json';
import { currentNetwork, gasParams, gasParamsApprove } from '../../utils/networkConfig';
import { disconnect, getBatchsData } from './walletBatch';
import { fromWei, toWei } from './walletUtils';
import { walletIdosData } from './walletIdo';
import { createNewBallot, walletBallotData } from './walletBallot';

export const walletConnect = async () => {
  if (window.ethereum) {
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      window.web3 = new Web3(window.ethereum);
      const accounts = await window.web3.eth.getAccounts();
      const networkId = await window.web3.eth.net.getId();
      let connected = true;
      if (networkId !== currentNetwork.network_id) {
        alert(
          `Intallez metamask et verifiez que vous etes sur le bon reseau (BSC => ${currentNetwork.name})`
        );
        disconnect();
        connected = false;
      }
      return {
        account: accounts[0],
        networkId,
        web3: window.web3,
        connected
      };
    } catch (err) {
      console.log('user did not add account...', err);
    }
  } else if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider);
  } else {
    window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
  }

  return {
    account: '',
    networkId: '',
    web3: window.web3,
    connected: false
  };
};

export const walletSetData = async (user) => {
  const statesData = user;
  const cryptoEduCapitalTokenData = CryptoEduCapitalToken.networks[statesData.networkId];
  if (cryptoEduCapitalTokenData) {
    const cryptoEduCapitalToken = new statesData.web3.eth.Contract(
      CryptoEduCapitalToken.abi,
      cryptoEduCapitalTokenData.address,
      gasParams
    );
    user.cryptoEduCapitalToken = cryptoEduCapitalToken;
    const cryptoEduCapitalTokenBalance = await cryptoEduCapitalToken.methods
      .balanceOf(user.account)
      .call();
    user.cryptoEduCapitalTokenBalance = statesData.web3.utils.fromWei(
      cryptoEduCapitalTokenBalance.toString(),
      'ether'
    );
  } else {
    window.alert('cryptoEduCapitalToken contract not deployed to detected network.');
  }
  const localNames = ['Bsc (Tesnet)', 'Development'];
  let busdTokenData;
  if (localNames.indexOf(currentNetwork.name) >= 0) {
    busdTokenData = FBusd.networks[statesData.networkId];
  } else {
    busdTokenData = CryptoEduCapitalToken.networks[statesData.networkId];
  }

  if (busdTokenData) {
    let busdToken;
    if (localNames.indexOf(currentNetwork.name) >= 0) {
      busdToken = new statesData.web3.eth.Contract(
        FBusd.abi,
        busdTokenData.address,
        gasParamsApprove
      );
    } else {
      busdToken = new statesData.web3.eth.Contract(
        FBusd.abi,
        // busdTokenData.address
        currentNetwork.addressList.busd,
        gasParamsApprove
      );
    }

    user.busdToken = busdToken;
    const busdTokenBalance = await busdToken.methods.balanceOf(statesData.account).call();
    user.busdTokenBalance = fromWei(statesData, busdTokenBalance);
  } else {
    window.alert('busdToken contract not deployed to detected network.');
  }

  const capitalManagerData = CapitalManager.networks[statesData.networkId];
  if (!capitalManagerData) return false;

  const capitalManager = new statesData.web3.eth.Contract(
    CapitalManager.abi,
    capitalManagerData.address,
    gasParams
  );
  user.capitalManager = capitalManager;
  if (currentNetwork.addressList.idoMainAddress === statesData.account) {
    user.isAdmin = await capitalManager.methods.isSuperAdmin().call({ from: statesData.account });
  } else {
    user.isAdmin = false;
  }

  const totalBusdDeposited = await capitalManager.methods.getTotalDepositedInAllBatch().call();
  user.totalBusdDeposited = fromWei(statesData, totalBusdDeposited);

  const batchsData = await getBatchsData(statesData);
  user.totalBatchNumber = batchsData.totalBatchNumber;

  let myTotalWeigth = 0;

  if (user.totalBusdDeposited > 0) {
    myTotalWeigth = (batchsData.myTotalDeposited / user.totalBusdDeposited) * 100;
  }
  user.batchDataList = batchsData.batchDataList;

  user.me = {
    myTotalDeposited: batchsData.myTotalDeposited,
    myTotalWeigth
  };
  user.idoDataList = await walletIdosData(statesData);
  user.ballotDataList = await walletBallotData(statesData);
  return user;
};
