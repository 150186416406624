import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Card, Stack, Link, Container, Typography } from '@material-ui/core';
// layouts
import React, { useContext } from 'react';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import AuthSocial from '../components/authentication/AuthSocial';
import { UserContext } from '../components/Context/UserContext';
import Footer from '../layouts/Footer';
import { walletConnect, walletSetData } from '../cryptos/contractsManager/walletConnector';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------
export default function Login() {
  const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  if (currentUser !== null && currentUser !== undefined && currentUser.connected) {
    walletConnect().then((data) => {
      const userCopy = { ...user };
      userCopy.loading = true;
      setUser(userCopy);
      if (!data.connected) return;
      userCopy.account = data.account;
      userCopy.networkId = data.networkId;
      userCopy.web3 = data.web3;
      userCopy.connected = data.connected;
      userCopy.loading = false;
      setUser(userCopy);
      const dataCollect = walletSetData(userCopy);
      // eslint-disable-next-line array-callback-return
      Object.keys(dataCollect).map((key) => {
        userCopy[key] = dataCollect[key];
      });
      setUser(userCopy);
      navigate('/dashboard', { replace: true });
    });
  }
  if (user.connected) {
    navigate('/dashboard', { replace: true });
  }
  return (
    <RootStyle title="Login | Crypto Edu">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Bienvenu sur Crypto Education!
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
          <Link variant="h3" href="http://a.mhi2s.xyz/G1FKL" sx={{ px: 5, mt: 10, mb: 5 }}>
            Livre Blanc »
          </Link>
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Connectez vous avec votre portefeuille
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Uniquement avec metamask pour momment.
            </Typography>
          </Stack>
          <AuthSocial />

          <LoginForm />

          <Stack sx={{ mb: 5 }}>
            <Footer />
          </Stack>
          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register">
                Get started
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
