// layouts
import React from 'react';
import { disconnect } from '../cryptos/contractsManager/walletBatch';

// ----------------------------------------------------------------------
export default function Logout() {
  disconnect();
  // eslint-disable-next-line no-restricted-globals
  location.reload();
  return <></>;
}
