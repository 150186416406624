// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BasicUserDataContext } from './components/Context/UserContext';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <BasicUserDataContext>
      <ThemeConfig>
        <ScrollToTop />
        <Router />
      </ThemeConfig>
    </BasicUserDataContext>
  );
}
