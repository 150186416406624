import React, { Component } from 'react';
import cecaImg from '../imgs/ceca.png';

// eslint-disable-next-line react/prefer-stateless-function
export default function CecaImage() {
  return (
    <span>
      <img src={cecaImg} height="20" alt="" style={{ display: 'inline' }} />
      CECA
    </span>
  );
}
