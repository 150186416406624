import IdoCryptoEduManager from '../abis/IdoCryptoEduManager.json';
import { displayErrorToast, displayToastHash } from '../../utils/utilsCeca';
import { mockImgIdo } from '../../utils/mockImages';
import { timeConverter } from './dateManager';
import { approve, fromWei, toWei } from './walletUtils';
import CeCaIdo from '../abis/CeCaIdo.json';
import ERC20 from '../abis/ERC20.json';
import { currentNetwork, gasParams } from '../../utils/networkConfig';

export const walletIdosData = async (statesData) => {
  const idoCryptoEduManager = IdoCryptoEduManager.networks[statesData.networkId];
  const idoCryptoEduManagerContact = new statesData.web3.eth.Contract(
    IdoCryptoEduManager.abi,
    idoCryptoEduManager.address
  );
  const totalIdoNumber = await idoCryptoEduManagerContact.methods.getIdoListSize().call();
  const idoDataList = [];
  for (let i = 0; i < totalIdoNumber.toString(); i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const aIdoAddress = await idoCryptoEduManagerContact.methods.idoInformationList(i).call();
    const ceCaIdo = getCeCaIdoContract(statesData, aIdoAddress);
    const aIdo = {};
    aIdo.idoId = aIdoAddress;
    // eslint-disable-next-line no-await-in-loop
    aIdo.totalDeposited = await ceCaIdo.methods.totalDeposited().call();

    aIdo.totalDeposited = fromWei(statesData, aIdo.totalDeposited);
    aIdo.image = mockImgIdo(aIdo.idoId);

    // eslint-disable-next-line no-await-in-loop
    aIdo.name = await ceCaIdo.methods.name().call();
    // eslint-disable-next-line no-await-in-loop
    const totalDeposited = await ceCaIdo.methods.totalDeposited().call();
    aIdo.totalDeposited = fromWei(statesData, totalDeposited.toString());
    // eslint-disable-next-line no-await-in-loop
    const numberOfTokenFromIdo = await ceCaIdo.methods.numberOfTokenFromIdo().call();
    aIdo.numberOfTokenFromIdo = fromWei(statesData, numberOfTokenFromIdo.toString());
    // eslint-disable-next-line no-await-in-loop
    const numberOfTokenDistributed = await ceCaIdo.methods.numberOfTokenDistributed().call();
    aIdo.numberOfTokenDistributed = fromWei(statesData, numberOfTokenDistributed.toString());
    // eslint-disable-next-line no-await-in-loop
    const idoTotalWeight = await ceCaIdo.methods.idoTotalWeight().call();
    aIdo.idoTotalWeight = fromWei(statesData, idoTotalWeight.toString());
    // eslint-disable-next-line no-await-in-loop
    aIdo.tokenAddress = await ceCaIdo.methods.tokenAddress().call();
    // eslint-disable-next-line no-await-in-loop
    const maxPerUser = await ceCaIdo.methods.maxPerUser().call();
    aIdo.maxPerUser = fromWei(statesData, maxPerUser.toString());
    // eslint-disable-next-line no-await-in-loop
    const priceSpentPerToken = await ceCaIdo.methods.priceSpentPerToken().call();
    aIdo.priceSpentPerToken = fromWei(statesData, priceSpentPerToken.toString());
    // eslint-disable-next-line no-await-in-loop
    aIdo.lockTimestamp = await ceCaIdo.methods.lockTimestamp().call();
    // eslint-disable-next-line no-await-in-loop
    aIdo.isLocked = await ceCaIdo.methods.isLocked().call();
    // eslint-disable-next-line no-await-in-loop
    aIdo.isCompleted = await ceCaIdo.methods.isCompleted().call();
    // eslint-disable-next-line no-await-in-loop
    aIdo.distributionEnded = await ceCaIdo.methods.distributionEnded().call();
    // eslint-disable-next-line no-await-in-loop
    aIdo.openedTimestamp = await ceCaIdo.methods.openedTimestamp().call();
    // eslint-disable-next-line no-await-in-loop
    aIdo.numberOfParticipants = await ceCaIdo.methods.getNumberOfParticipantOfIdo().call();
    try {
      // eslint-disable-next-line no-await-in-loop
      aIdo.isEligible = await ceCaIdo.methods.isEligible().call({ from: statesData.account });
    } catch (e) {
      aIdo.isEligible = false;
      console.log(e);
    }
    // eslint-disable-next-line no-await-in-loop
    const balance = await ceCaIdo.methods.listOfParticipant(statesData.account).call();
    aIdo.balance = fromWei(statesData, balance.toString());

    aIdo.maxCanDeposit = aIdo.maxPerUser - aIdo.balance;

    // eslint-disable-next-line no-await-in-loop
    const weight = await ceCaIdo.methods.weightOfParticipant(statesData.account).call();
    aIdo.weight = weight.toString();

    if (aIdo.lockTimestamp !== '' && aIdo.isLocked) {
      aIdo.lockTimestamp = timeConverter(aIdo.lockTimestamp);
    } else {
      aIdo.lockTimestamp = 'Encore Ouvert';
    }
    aIdo.openedTimestamp = timeConverter(aIdo.openedTimestamp);
    idoDataList.push({ aIdo });
  }
  return idoDataList;
};

export const createNewIdo = async (statesData, name) => {
  try {
    const idoCryptoEduManager = IdoCryptoEduManager.networks[statesData.networkId];
    const idoCryptoEduManagerContact = new statesData.web3.eth.Contract(
      IdoCryptoEduManager.abi,
      idoCryptoEduManager.address
    );
    idoCryptoEduManagerContact.methods
      .initialiseNewIdo(name, toWei(statesData, '5'))
      .send({ from: statesData.account, gasPrice: gasParams.gasPrice, gas: '3806802' })
      .on('transactionHash', (hash) => {
        displayToastHash(hash);
      });
  } catch (e) {
    displayErrorToast("Une erreur s'est produite");
  }
};

export const walletLockIdo = async (statesData, idoId) => {
  try {
    const ceCaIdo = getCeCaIdoContract(statesData, idoId);
    await ceCaIdo.methods
      .idoLockDeposit()
      .send({ from: statesData.account, gasPrice: gasParams.gasPrice, gas: '80000' })
      .on('transactionHash', (hash) => {
        displayToastHash(hash);
      });
  } catch (e) {
    displayErrorToast("Une erreur s'est produite");
  }
};

// this function should be call by the idoBusdAddress
export const walletSetIdoTokenInformation = async (
  statesData,
  _tokenAddress,
  _numberOfToken,
  _totalBusdSpent,
  _idoId
) => {
  const ceCaIdo = getCeCaIdoContract(statesData, _idoId);
  const isLocked = await ceCaIdo.methods.isLocked().call();
  if (!isLocked) {
    displayErrorToast('IDO non clos');
    return;
  }
  try {
    await approve(
      statesData,
      statesData.busdToken,
      _idoId,
      statesData.account,
      toWei(statesData, (_numberOfToken * _totalBusdSpent).toString())
    );
    const tokenAddressFromIdo = getErc20Contract(statesData, _tokenAddress.toString());
    await approve(
      statesData,
      tokenAddressFromIdo,
      _idoId,
      statesData.account,
      toWei(statesData, (_numberOfToken * _totalBusdSpent).toString())
    );

    await ceCaIdo.methods
      .setIdoToken(
        _tokenAddress.toString(),
        toWei(statesData, _numberOfToken.toString().trim()),
        toWei(statesData, _totalBusdSpent.toString().trim())
      )
      .send({ from: statesData.account, gasPrice: gasParams.gasPrice, gas: '300000' })
      .on('transactionHash', (hash) => {
        displayToastHash(hash);
      });
  } catch (e) {
    displayErrorToast("Une erreur s'est produite");
  }
};

export const idoParticipate = async (statesData, idoId, amount) => {
  if (parseFloat(amount) > parseFloat(statesData.busdTokenBalance)) {
    displayErrorToast('Solde Insuffisant');
    return false;
  }
  const ceCaIdo = getCeCaIdoContract(statesData, idoId);
  const balance = await ceCaIdo.methods.listOfParticipant(statesData.account).call();
  const balanceInIdo = fromWei(statesData, balance.toString());
  const maxPerUser = await ceCaIdo.methods.maxPerUser().call();
  const maxPerUserIdo = fromWei(statesData, maxPerUser.toString());

  const maxCanDeposit = maxPerUserIdo - balanceInIdo;
  if (amount > maxCanDeposit) {
    displayErrorToast('Superiere au montant max pour cet Ido');
  }
  amount = toWei(statesData, amount);

  let gasToUse = '115000';
  if (parseInt(balanceInIdo, 10) === 0) {
    gasToUse = '260000';
  }
  try {
    await approve(statesData, statesData.busdToken, idoId, statesData.account, amount);
    await ceCaIdo.methods
      .depositForIdo(amount)
      .send({ from: statesData.account, gasPrice: gasParams.gasPrice, gas: gasToUse })
      .on('transactionHash', (hash) => {
        displayToastHash(hash);
      });
  } catch (e) {
    console.log(e);
    displayErrorToast('Une erreur est survenue. Veillez rafraichir la page.');
  }
};

export const idoRedistribute = async (statesData, idoId) => {
  const ceCaIdo = getCeCaIdoContract(statesData, idoId);
  if (currentNetwork.addressList.idoMainAddress !== statesData.account) {
    displayErrorToast("Seule l'adresse de principale peut le faire");
    // return;
  }
  try {
    await approve(statesData, ceCaIdo, idoId, statesData.account, '1000');
    await ceCaIdo.methods
      .redistributeIdoToken()
      .send({ from: statesData.account })
      .on('transactionHash', (hash) => {
        displayToastHash(hash);
      });
  } catch (e) {
    displayErrorToast(`Une erreur est survenue. Veillez rafraichir la page.${e}`);
  }
};

function getCeCaIdoContract(statesData, idoId) {
  return new statesData.web3.eth.Contract(CeCaIdo.abi, idoId, gasParams);
}

function getErc20Contract(statesData, contractAddress) {
  return new statesData.web3.eth.Contract(ERC20.abi, contractAddress, gasParams);
}
