import { createContext, useState } from 'react';

export const UserContext = createContext();

export const BasicUserDataContext = (props) => {
  const [user, setUser] = useState({
    account: '0x0',
    cryptoEduCapitalToken: {},
    busdToken: {},
    capitalManager: {},
    cryptoEduCapitalTokenBalance: '0',
    busdTokenBalance: '0',
    totalBusdDeposited: '0',
    totalBatchNumber: '0',
    me: {
      myTotalDeposited: '0',
      myBalanceDepositedBefore: '0',
      myTotalWeigth: '0'
    },
    connected: false,
    web3: {},
    networkId: '',
    user: '',
    auth_token: '',
    loading: false,
    batchDataList: []
  });
  return <UserContext.Provider value={[user, setUser]}>{props.children}</UserContext.Provider>;
};
