import React, { useContext } from 'react';
// material
import { Container, Typography } from '@material-ui/core';
// components
import { Alert, AlertTitle } from '@material-ui/lab';
import Page from '../components/Page';
//
import { UserContext } from '../components/Context/UserContext';
import IdosList from '../cryptos/display/IdosList';
import CecaImage from '../cryptos/images/CecaImage';

// ----------------------------------------------------------------------

export default function Idos() {
  const [user] = useContext(UserContext);
  return (
    <Page title="Dashboard: Liste des projets IDOS | Capital Manager">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Liste des projets IDOS (Solde: ${user.busdTokenBalance} et <CecaImage />{' '}
          {user.cryptoEduCapitalTokenBalance})
        </Typography>
        {user.idoDataList.length > 0 && <IdosList batchDataList={user.idoDataList} user={user} />}
        {user.idoDataList.length === 0 && (
          <Alert variant="filled" severity="info">
            <AlertTitle>OUPS</AlertTitle>
            Pas de IDO pour le moment. Revenez verifier de temps de en temps.
            <br /> Merci!
          </Alert>
        )}
      </Container>
    </Page>
  );
}
