import PropTypes from 'prop-types';
// material
import { Grid, Link, Stack, TextareaAutosize, TextField } from '@material-ui/core';
import { Component } from 'react';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@material-ui/lab';
import editFill from '@iconify/icons-eva/edit-fill';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { createNewBatch, walletRedistributeToken } from '../contractsManager/walletBatch';
import { createNewIdo } from '../contractsManager/walletIdo';
import { displayErrorToast } from '../../utils/utilsCeca';
import { createNewBallot } from '../contractsManager/walletBallot';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(3),
  height: theme.spacing(2),
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
  color: theme.palette.background.default,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.error.dark,
    0.24
  )} 100%)`
}));

class AdminPage extends Component {
  constructor(props) {
    super(props);
    const { user, ...other } = this.props;
    this.state = {
      descriptionBatch: '',
      loading: false,
      user,
      // eslint-disable-next-line react/no-unused-state
      other
    };
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleCreateNewBatch = this.handleCreateNewBatch.bind(this);
    this.handleCreateNewIdo = this.handleCreateNewIdo.bind(this);
    this.handlelisteDistrubutionBatchChange = this.handlelisteDistrubutionBatchChange.bind(this);
    this.redistributeWallet = this.redistributeWallet.bind(this);
    this.createBallot = this.createBallot.bind(this);
  }

  handleDescriptionChange(event) {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ descriptionBatch: event.target.value });
  }

  handlelisteDistrubutionBatchChange(event) {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ listeDistrubutionBatch: event.target.value });
  }

  async handleCreateNewBatch(event) {
    event.preventDefault();
    try {
      this.state.loading = true;
      // if (this.state.montantDepot < this.state.batch.busdTokenBalance)
      await createNewBatch(this.state.user, this.state.descriptionBatch);
      await (this.state.loading = false);
      this.setState({ descriptionBatch: '' });
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  async handleCreateNewIdo(event) {
    event.preventDefault();
    try {
      this.state.loading = true;
      // if (this.state.montantDepot < this.state.batch.busdTokenBalance)
      await createNewIdo(this.state.user, this.state.descriptionBatch);
      await (this.state.loading = false);
      this.setState({ descriptionBatch: '' });
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  onSubmitNothing() {
    return false;
  }

  async createBallot(event) {
    event.preventDefault();
    try {
      this.state.loading = true;
      const dataList = this.state.listeDistrubutionBatch.split('\n');
      const ballotName = dataList[0].trim();
      const propopals = [];
      for (let i = 1; i < dataList.length; i += 1) {
        if (dataList[i].trim().length > 0) {
          propopals.push(dataList[i].trim());
        }
      }
      console.log(propopals);
      if (propopals.length < 2) {
        displayErrorToast('Liste des proposition < 2');
        this.state.loading = false;
        return false;
      }
      await createNewBallot(this.state.user, ballotName, propopals);
      this.state.loading = false;
      this.setState({ listeDistrubutionBatch: '' });
    } catch (e) {
      console.log(e);
      displayErrorToast('Une erreur est survenue');
    }
  }

  async redistributeWallet(event) {
    event.preventDefault();
    try {
      this.state.loading = true;
      const dataList = this.state.listeDistrubutionBatch.split('\n');
      const receivers = [];
      const amounts = [];
      for (let i = 0; i < dataList.length; i += 1) {
        const line = dataList[i].trim().split(',');
        if (line.length < 2) {
          // eslint-disable-next-line no-continue
          continue;
        }
        line[0] = line[0].trim();
        line[1] = parseFloat(line[1].trim());
        if (line[0].length > 10 && line[1] > 0) {
          receivers.push(line[0].trim());
          amounts.push(line[1].toString());
        }
      }
      if (receivers.length < 1 || receivers.length !== amounts.length) return false;
      await walletRedistributeToken(this.state.user, receivers, amounts);
      this.state.loading = false;
      this.setState({ listeDistrubutionBatch: '' });
    } catch (e) {
      displayErrorToast('Une erreur est survenue');
    }
    return true;
  }

  render() {
    return (
      <>
        <Grid container spacing={3}>
          {this.state.user.isAdmin && (
            <>
              <form onSubmit={this.onSubmitNothing}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ p: 3 }}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Description"
                    value={this.state.descriptionBatch}
                    onChange={this.handleDescriptionChange}
                  />
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={this.handleCreateNewBatch}
                  >
                    Creer un Nouveau Batch
                    <IconWrapperStyle>
                      <Icon icon={editFill} width={44} height={44} />
                    </IconWrapperStyle>
                  </LoadingButton>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={this.handleCreateNewIdo}
                  >
                    Creer un Nouvel IDO
                    <IconWrapperStyle>
                      <Icon icon={editFill} width={44} height={44} />
                    </IconWrapperStyle>
                  </LoadingButton>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={5} sx={{ p: 5 }}>
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Liste de addresses separes par les e montant (0x11111,122), Ou les votes et les propositions sur une ligne chaque"
                    label=""
                    value={this.state.listeDistrubutionBatch}
                    onChange={this.handlelisteDistrubutionBatchChange}
                    loading={this.state.loading}
                  />
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={this.redistributeWallet}
                    loading={this.state.loading}
                  >
                    Redistribuer
                    <IconWrapperStyle>
                      <Icon icon={editFill} width={44} height={44} />
                    </IconWrapperStyle>
                  </LoadingButton>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={this.createBallot}
                    loading={this.state.loading}
                  >
                    Ajouter Un Vote
                    <IconWrapperStyle>
                      <Icon icon={editFill} width={44} height={44} />
                    </IconWrapperStyle>
                  </LoadingButton>
                </Stack>
              </form>
              <Link href="https://cryptoedu.xyz/images_manger.php" target="_blank">
                {' '}
                Images
              </Link>
            </>
          )}
        </Grid>
      </>
    );
  }
}
export default AdminPage;
