// material
import { Box, Card, Typography, Stack, TextField, Divider } from '@material-ui/core';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
// utils
//
import { Icon } from '@iconify/react';
import { LoadingButton } from '@material-ui/lab';
import forward from '@iconify/icons-ant-design/forward-fill';
import share from '@iconify/icons-ant-design/share-alt';
import dollar from '@iconify/icons-ant-design/dollar';
import { Component } from 'react';
import lockFill from '@iconify/icons-eva/lock-fill';
import Label from '../../components/Label';
import { walletLockBatch, walletStakeTokens } from '../contractsManager/walletBatch';
import { displayErrorToast, tokenHref } from '../../utils/utilsCeca';

// ----------------------------------------------------------------------

const BatchImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});
const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(3),
  height: theme.spacing(2),
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
  color: theme.palette.background.default,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.error.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

class BatchCard extends Component {
  constructor(props) {
    super(props);
    const { batch, user } = this.props;
    this.state = {
      montantDepot: batch.busdTokenBalance,
      loading: false,
      // eslint-disable-next-line react/no-unused-state
      batch,
      user
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.lockBatch = this.lockBatch.bind(this);
  }

  handleChange(event) {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ montantDepot: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();
    try {
      // this.state.loading = true;
      await walletStakeTokens(this.state.user, this.state.batch.batchId, this.state.montantDepot);
      this.setState({ montantDepot: '' });
      this.state.loading = false;
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  async lockBatch() {
    try {
      this.state.loading = true;
      // if (this.state.montantDepot < this.state.batch.busdTokenBalance)
      await walletLockBatch(this.state.user, this.state.batch.batchId);
      await (this.state.loading = false);
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  render() {
    const { batch } = this.props;
    // const dataCollect = await walletConnector.setData(userCopy);
    // const [montantDepot] = useState(false);
    return (
      <Card>
        <Box sx={{ pt: '50%', position: 'relative' }}>
          <Label
            variant="filled"
            color={(batch.isLocked && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            {(batch.isLocked && 'Close') || 'Open'}
          </Label>

          <BatchImgStyle alt={batch.name} src={batch.cover} />
        </Box>

        <Stack spacing={2} sx={{ p: 3 }}>
          <Typography variant="subtitle2" noWrap>
            {batch.name} # {tokenHref(batch.batchId)} <br />
            {batch.description}
          </Typography>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Typography variant="subtitle2">Dépôt : {batch.totalDeposited} $</Typography>
              <Typography variant="subtitle2">Participants : {batch.batchParticipants}</Typography>
            </Stack>
            <Divider />
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
              <Typography variant="subtitle2">
                Ouvert le :<br /> {batch.openedTimestamp}
              </Typography>
              {batch.lockTimestamp !== 'Encore Ouvert' && (
                <Typography variant="subtitle2">
                  Bloqué le :<br /> {batch.lockTimestamp}
                </Typography>
              )}
              {batch.ClaimDateBegin !== 'Encore Ouvert' && (
                <Typography variant="subtitle2">
                  Réclamation à partir de : <br />
                  {batch.ClaimDateBegin}
                </Typography>
              )}
            </Stack>
            <Divider />
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} bgcolor="#e8f7ff">
              <Typography variant="subtitle2">Moi : {batch.myBatchCap} $</Typography>
              <Typography variant="subtitle2" align="left">
                Poids : {batch.myBatchPercentage} %
              </Typography>
            </Stack>

            <form className="mb-3" onSubmit={this.handleSubmit}>
              Solde : ${this.state.user.busdTokenBalance}
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                {!batch.isLocked && (
                  <TextField
                    fullWidth
                    type="number"
                    label="Montant"
                    max={batch.busdTokenBalance}
                    value={this.state.montantDepot}
                    onChange={this.handleChange}
                  />
                )}
                {!batch.isLocked && (
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={this.state.loading}
                  >
                    Déposer
                    <IconWrapperStyle>
                      <Icon icon={dollar} width={44} height={44} />
                    </IconWrapperStyle>
                  </LoadingButton>
                )}
              </Stack>
            </form>
            {batch.canbeTransfered && (
              <LoadingButton fullWidth size="large" type="submit" variant="contained">
                Transférer {batch.openedTimestamp}
                <IconWrapperStyle>
                  <Icon icon={forward} width={44} height={44} />
                </IconWrapperStyle>
              </LoadingButton>
            )}
            {this.state.user.isAdmin && (
              <>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={this.lockBatch}
                >
                  Blocquer pour (1)un an
                  <IconWrapperStyle>
                    <Icon icon={lockFill} width={44} height={44} />
                  </IconWrapperStyle>
                </LoadingButton>
              </>
            )}
          </Stack>
        </Stack>
      </Card>
    );
  }
}

export default BatchCard;
