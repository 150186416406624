// material
import { Box, Typography, Stack, Divider, Link, TextareaAutosize } from '@material-ui/core';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
// utils
//
import React from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';

import { makeStyles } from '@material-ui/styles';
import { Alert, AlertTitle, LoadingButton } from '@material-ui/lab';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { displayErrorToast } from '../../utils/utilsCeca';
import { ballotVoteParticipate, walletLockBallot } from '../contractsManager/walletBallot';

// ----------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  imageIdo: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    width: '3%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'block'
  }
}));

// ----------------------------------------------------------------------

export default function BallotCard({ ballot, user, className }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [ballotAction, setBallotAction] = React.useState(false);

  if (className === 'opened' && !ballot.isCompleted) {
    className = classes.visible;
  } else {
    className = classes.hidden;
  }

  const boxClass = ballot.isLocked ? 'error.main' : 'background.paper';

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeProposal = (event, proposal) => {
    voteBallot(proposal);
  };

  const lockBallot = async () => {
    try {
      await walletLockBallot(user, ballot.id);
    } catch (e) {
      displayErrorToast('Une erreure est survenue');
    }
    return false;
  };

  const voteBallot = async (proposal) => {
    try {
      await ballotVoteParticipate(user, ballot.id, proposal);
    } catch (e) {
      displayErrorToast('Une erreure est survenue');
    }
    return false;
  };
  const voteBallotNoAction = () => false;

  return (
    <Box boxShadow={3} bgcolor={boxClass} m={1} p={1} className={className}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={7}>
            <Typography variant="subtitle2">{ballot.name}</Typography>
            <Typography variant="subtitle2">
              Top proposition: {ballot.proposalsList[ballot.winning].name}(
              {ballot.proposalsList[ballot.winning].weight}%)
            </Typography>
            <Typography variant="subtitle2">
              {ballot.completed && (
                <Typography variant="subtitle2">Vote Clos(Impossible de voter)</Typography>
              )}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={5}>
            {ballot.proposalsList.map((proposal) => (
              <Typography variant="subtitle2">
                {' '}
                {proposal.name} ({proposal.weight}%)
                {!ballot.completed && (
                  <ToggleButtonGroup
                    orientation="horizontal"
                    value={ballotAction}
                    exclusive
                    onChange={handleChangeProposal}
                  >
                    <ToggleButton value={proposal.index} aria-label="list">
                      <Link href="#" onClick={voteBallotNoAction}>
                        Voter
                      </Link>
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Typography>
            ))}
          </Stack>
          <Divider />
          {user.isAdmin && (
            <Alert variant="filled" severity="warning">
              <AlertTitle>Administration</AlertTitle>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={5}>
                {!ballot.completed && (
                  <Link href="#" onClick={lockBallot}>
                    Bloquer
                  </Link>
                )}
              </Stack>
            </Alert>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
