const acceptedNetWork = {
  development: {
    name: 'Development',
    host: '127.0.0.1',
    port: 7545,
    network_id: 5777,
    explorer: 'https://localhost:7575',
    addressList: {
      busd: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      capitalDeposit: '0x4328335Cbe74D1fC60b5011954076534D7244494',
      idoMainAddress: '0x4328335Cbe74D1fC60b5011954076534D7244494',
      idoBusdAddress: '0x4328335Cbe74D1fC60b5011954076534D7244494',
      teamAddress: '0x9B3a3Cc32645D845a25e3c07e9EeC02c2528331b'
    }
  },
  testnet: {
    name: 'Bsc (Tesnet)',
    network_id: 97,
    confirmations: 5,
    timeoutBlocks: 200,
    skipDryRun: true,
    explorer: 'https://testnet.bscscan.com',
    addressList: {
      busd: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      capitalDeposit: '0x4328335Cbe74D1fC60b5011954076534D7244494',
      idoMainAddress: '0x4328335Cbe74D1fC60b5011954076534D7244494',
      idoBusdAddress: '0x4328335Cbe74D1fC60b5011954076534D7244494',
      teamAddress: '0x9B3a3Cc32645D845a25e3c07e9EeC02c2528331b'
    }
  },
  bsc: {
    name: 'Bsc (Mainnet)',
    network_id: 56,
    confirmations: 10,
    timeoutBlocks: 200,
    skipDryRun: true,
    explorer: 'https://bscscan.com',
    addressList: {
      busd: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      capitalDeposit: '0xE820cf29813939d84160FEbe5Aa1bd52422b1366',
      idoMainAddress: '0xA6A97c85Bd58B4ABd5d5578b4221c8c80B9aB382',
      idoBusdAddress: '0xD7AcE5005CE05f7e4F63331cd384c4E49B850C1e',
      teamAddress: '0x9B3a3Cc32645D845a25e3c07e9EeC02c2528331b'
    }
  },
  ethTestnet: {
    name: 'Ethereum (Testnet)',
    network_id: 3,
    confirmations: 5,
    timeoutBlocks: 200,
    skipDryRun: true,
    explorer: 'https://ropsten.etherscan.io',
    addressList: {
      busd: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      capitalDeposit: '0xE820cf29813939d84160FEbe5Aa1bd52422b1366',
      idoMainAddress: '0xA6A97c85Bd58B4ABd5d5578b4221c8c80B9aB382',
      idoBusdAddress: '0xD7AcE5005CE05f7e4F63331cd384c4E49B850C1e',
      teamAddress: '0x9B3a3Cc32645D845a25e3c07e9EeC02c2528331b'
    }
  }
};
export const currentNetwork = acceptedNetWork.bsc;

export const gasParams = {
  gasPrice: '5000000000',
  gas: '1000000'
};

export const gasParamsApprove = {
  gasPrice: '5000000000',
  gas: '45000'
};
