import React from 'react';
import { Typography, Link, AppBar, Container, Toolbar } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import heart from '@iconify/icons-ant-design/heart-fill';
import { makeStyles } from '@material-ui/styles';
import { Icon } from '@iconify/react';
import { ToastContainer } from 'react-toastify';

const FooterContainer = styled('div')({
  textAlign: 'center',
  position: 'absolute',
  bottom: '0',
  width: '30% !important',
  height: '2% !important',
  display: 'flex',
  flexDirection: 'column'
});

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0)
  },
  paper: {
    paddingBottom: 50
  },
  list: {
    marginBottom: theme.spacing(2)
  },
  subheader: {
    backgroundColor: theme.palette.background.paper
  },
  appBar: {
    top: 'auto',
    bottom: 0
  },
  grow: {
    flexGrow: 1
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto'
  }
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <AppBar position="fixed" color="inherit" className={classes.appBar}>
      <Container maxWidth="md">
        <Toolbar>
          <Typography variant="body1" color="inherit">
            © 2021 Made with <Icon icon={heart} width={20} height={20} color="red" />{' '}
            <Link href="https://www.elviskonjoh.com" target="_blank">
              by Elvis KONJOH{' '}
            </Link>
          </Typography>
          <ToastContainer />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
