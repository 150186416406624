import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import CheckIcon from '@material-ui/icons/Check';
import Delete from '@material-ui/icons/Delete';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { UserContext } from '../../components/Context/UserContext';
import BallotCard from './BallotCard';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20
  },
  details: {
    alignItems: 'center'
  },
  column: {
    flexBasis: '33.33%'
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

export default function BallotList() {
  const [user] = useContext(UserContext);
  const classes = useStyles();
  const [view, setView] = useState('opened');

  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  return (
    <div className={classes.root}>
      <ToggleButtonGroup orientation="horizontal" value={view} exclusive onChange={handleChange}>
        <ToggleButton value="opened" aria-label="list">
          Overt:
          <CheckIcon />
        </ToggleButton>
        <ToggleButton value="completed" aria-label="module">
          Fermé:
          <Delete />
        </ToggleButton>
      </ToggleButtonGroup>
      {user.ballotDataList.map((ballot) => (
        <BallotCard ballot={ballot} user={user} className={view} />
      ))}
    </div>
  );
}
