// material
import React from 'react';
import { Box, Grid, Container, Typography } from '@material-ui/core';
// components
import { useNavigate } from 'react-router-dom';
import Page from '../components/Page';
import {
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppWeeklySales,
  AppTrafficBySite
} from '../components/_dashboard/app';
import CecaImage from '../cryptos/images/CecaImage';
import 'react-toastify/dist/ReactToastify.css';
import { truncate } from '../utils/utilsCeca';
// ----------------------------------------------------------------------

export default function DashboardApp({ user }) {
  const navigate = useNavigate();
  if (!user.connected) {
    navigate('/login', { replace: true });
    return <></>;
  }

  return (
    <Page title="Dashboard | Capital Manager">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">
            Hi, Welcome back! {user.name} {truncate(user.account, 15)}
            (Solde: {user.cryptoEduCapitalTokenBalance} <CecaImage />)
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales user={user} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers user={user} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders user={user} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports user={user} />
          </Grid>
          <Grid item xs={12} md={6} lg={12}>
            <AppTrafficBySite user={user} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
