import { useContext, useState } from 'react';
// material
import { Container, Stack, Typography } from '@material-ui/core';
// components
import Page from '../components/Page';
//
import { UserContext } from '../components/Context/UserContext';
import AdminPage from '../cryptos/display/AdminPage';

// ----------------------------------------------------------------------

export default function Admin() {
  const [user, setUser] = useContext(UserContext);
  return (
    <Page title="Dashboard: Gestion du Capital | Capital Manager">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Gestion du Capital
        </Typography>

        <AdminPage user={user} />
      </Container>
    </Page>
  );
}
