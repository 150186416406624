import { useContext, useState } from 'react';
// material
import { Container, Typography } from '@material-ui/core';
// components
import Page from '../components/Page';
import BatchList from '../cryptos/display/BatchList';
//
import { UserContext } from '../components/Context/UserContext';

// ----------------------------------------------------------------------

export default function Batches() {
  const [user] = useContext(UserContext);
  return (
    <Page title="Dashboard: Gestion du Capital | Capital Manager">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Gestion du Capital
        </Typography>
        <BatchList batchDataList={user.batchDataList} user={user} />
      </Container>
    </Page>
  );
}
