import { displayErrorToast, displayToastHash } from '../../utils/utilsCeca';
import { approve, fromWei, toWei } from './walletUtils';
import BallotsManager from '../abis/BallotsManager.json';
import Ballot from '../abis/Ballot.json';
import { currentNetwork, gasParams } from '../../utils/networkConfig';

export const walletBallotData = async (statesData) => {
  const ballotsManagerContact = getBallotManager(statesData);
  const totalBallotNumber = await ballotsManagerContact.methods.getBallotListSize().call();
  const ballotDataList = [];
  for (let i = 0; i < totalBallotNumber.toString(); i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const aBallotAddress = await ballotsManagerContact.methods.ballotsList(i).call();
    const ceCaBallot = getBallotContract(statesData, aBallotAddress);
    const aBallot = {};
    aBallot.id = aBallotAddress;
    // eslint-disable-next-line no-await-in-loop
    aBallot.name = await ceCaBallot.methods.name().call();
    // eslint-disable-next-line no-await-in-loop
    aBallot.completed = await ceCaBallot.methods.completed().call();
    // eslint-disable-next-line no-await-in-loop
    aBallot.nbVoters = await ceCaBallot.methods.nbVoters().call();

    // eslint-disable-next-line no-await-in-loop
    aBallot.winning = await ceCaBallot.methods.winningProposal().call();

    // eslint-disable-next-line no-await-in-loop
    aBallot.proposalSize = await ceCaBallot.methods.getProposalSize().call();

    aBallot.proposalsList = [];
    let totalVotesWeigth = 0;
    for (let j = 0; j < aBallot.proposalSize; j += 1) {
      // eslint-disable-next-line no-await-in-loop
      const aProposal = await ceCaBallot.methods.proposals(j).call();
      aProposal.weight = 0;
      aProposal.index = j;
      aBallot.proposalsList.push(aProposal);
      totalVotesWeigth += parseFloat(aProposal.voteCount);
    }
    for (let j = 0; j < aBallot.proposalSize; j += 1) {
      if (totalVotesWeigth > 0) {
        aBallot.proposalsList[j].weight = (
          (aBallot.proposalsList[j].voteCount / totalVotesWeigth) *
          100
        ).toFixed(2);
      }
    }
    ballotDataList.push(aBallot);
  }
  return ballotDataList;
};

export const createNewBallot = async (statesData, name, proposals) => {
  try {
    const ballotsManagerContact = getBallotManager(statesData);
    ballotsManagerContact.methods
      .initialiseNewBallot(name, proposals)
      .send({ from: statesData.account, gasPrice: gasParams.gasPrice, gas: '1806802' })
      .on('transactionHash', (hash) => {
        displayToastHash(hash);
      });
  } catch (e) {
    displayErrorToast("Une erreur s'est produite");
  }
};

export const walletLockBallot = async (statesData, idBallot) => {
  try {
    const ceCaBallot = getBallotContract(statesData, idBallot);
    await ceCaBallot.methods
      .lockVote()
      .send({ from: statesData.account, gasPrice: gasParams.gasPrice, gas: '60000' })
      .on('transactionHash', (hash) => {
        displayToastHash(hash);
      });
  } catch (e) {
    displayErrorToast("Une erreur s'est produite");
  }
};

export const ballotVoteParticipate = async (statesData, idBallot, proposal) => {
  const ceCaBallot = getBallotContract(statesData, idBallot);
  const completed = await ceCaBallot.methods.completed().call();
  const voterInfo = await ceCaBallot.methods.voters(statesData.account).call();
  const eligibility = await ceCaBallot.methods.checkEligibility(statesData.account).call();
  const proposalSize = await ceCaBallot.methods.getProposalSize().call();
  if (completed || voterInfo.voted || !eligibility) {
    displayErrorToast("Vote clos ou alors vous n'etes pas elibible ou vous avez deja vote");
    return false;
  }
  proposal = parseInt(proposal, 10);

  if (proposal < 0 || proposal >= proposalSize) {
    return false;
  }
  try {
    await ceCaBallot.methods
      .vote(proposal.toString())
      .send({ from: statesData.account, gasPrice: gasParams.gasPrice, gas: '200000' })
      .on('transactionHash', (hash) => {
        displayToastHash(hash);
      });
  } catch (e) {
    displayErrorToast("Une erreur s'est produite");
  }
};

function getBallotContract(statesData, ballotId) {
  return new statesData.web3.eth.Contract(Ballot.abi, ballotId, gasParams);
}

function getBallotManager(statesData) {
  const ballotsManager = BallotsManager.networks[statesData.networkId];
  const ballotsManagerContact = new statesData.web3.eth.Contract(
    BallotsManager.abi,
    ballotsManager.address
  );
  return ballotsManagerContact;
}
