import { displayToastHash } from '../../utils/utilsCeca';
import { gasParamsApprove } from '../../utils/networkConfig';

export const toWei = (statesData, amount) => statesData.web3.utils.toWei(amount, 'Ether');

export const fromWei = (statesData, amount) => statesData.web3.utils.fromWei(amount, 'Ether');

export const disconnect = () => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
  sessionStorage.clear();
  localStorage.removeItem('currentUser');
};

export const approve = async (statesData, contract, address, from, amount) => {
  let allowanceBefore = await contract.methods.allowance(from, address).call();
  allowanceBefore = fromWei(statesData, allowanceBefore.toString());
  const amountFroWei = fromWei(statesData, amount);
  if (parseFloat(allowanceBefore) < parseFloat(amountFroWei)) {
    await contract.methods
      .approve(address, '100000000000000000000000000000000')
      .send({ from, gasPrice: gasParamsApprove.gasPrice, gas: gasParamsApprove.gas })
      .on('transactionHash', (hash) => {
        displayToastHash(hash);
      });
  }
};
